import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "@mui/material";
import moment from "moment";

import ModalFilterGrafik from "./ModalFilterGrafik";
import BarChart from "../../components/charts/bar";
import LineChart from "../../components/charts/line";
import GrafikCard from "../../components/commons/card";

import { listGraph, filterGraph } from "../../store/actions/dashboard";
import { getFilterGraph } from "../../store/reducers/dashboard";
import {
  colorGraphs,
  indikatorGrafikBodyWeight,
  indikatorGrafikCumMortality,
  indikatorGrafikFeedConsumption,
  indikatorGrafikUniformity,
  indikatorGrafikWaterConsumption,
  indikatorGrafikWeeklyMortality,
  indikatorGrafikWeightGain,
  infoGraphDashboard,
  mapAndSortData
} from "../../utils/constants";

const Index = (props) => {
  // load props data
  const { filters } = props;

  const [showModalFilter, setShowModalFilter] = useState(false);
  const [tkChartData, setTkChartData] = useState([]);
  const [feedConsumptionData, setFeedConsumptionData] = useState([]);
  const [waterConsumptionData, setWaterConsumptionData] = useState([]);
  const [weeklyMortalityData, setWeeklyMortalityData] = useState([]);
  const [bodyWeightData, setBodyWeightData] = useState([]);
  const [differenceBodyWeightData, setDifferenceBodyWeightData] = useState([]);
  const [uniformityData, setUniformityData] = useState([]);
  const [weeklyGainData, setWeeklyGainData] = useState([]);

  useEffect(() => {
    const formattedStartDate = filters?.start_date ?? moment().subtract(1, 'months').format("DD-MM-YYYY");
    const formattedEndDate = filters?.end_date ?? moment().format("DD-MM-YYYY");

    let param = {
      id_farm: filters?.id_farm ? filters?.id_farm?.value : "",
      id_cage: filters?.id_cage ? filters?.id_cage?.value : "",
      graphs: filters?.graph,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    };

    props.listGraph(param, getGraphSuccess);
  }, [filters]);

  const getGraphSuccess = (data) => {
    if (data && data?.cumulative_mortality) {
      setTkChartData(
        mapAndSortData(data?.cumulative_mortality, ['mortality', 'standart'])
      );
    } else {
      setTkChartData([]);
    }

    if (data && data?.feed_consumption) {
      setFeedConsumptionData(
        mapAndSortData(data?.feed_consumption, ['berat_feed', 'feed_intake', 'feed_intake_standart'])
      );
    } else {
      setFeedConsumptionData([]);
    }

    if (data && data?.water_consumption) {
      setWaterConsumptionData(
        mapAndSortData(data?.water_consumption, ['water_consumption', 'water_egg_ratio', 'water_intake_standart'])
      );
    } else {
      setWaterConsumptionData([]);
    }

    if (data && data?.weekly_mortality) {
      setWeeklyMortalityData(
        mapAndSortData(data?.weekly_mortality, ['weekly_mortality'])
      );
    } else {
      setWeeklyMortalityData([]);
    }

    if (data && data?.body_weight) {
      setBodyWeightData(
        mapAndSortData(data?.body_weight, ['body_weight_actual', 'body_weight_standart'])
      );
    } else {
      setBodyWeightData([]);
    }

    if (data && data?.difference) {
      setDifferenceBodyWeightData(
        mapAndSortData(data?.difference, ['difference'])
      );
    } else {
      setDifferenceBodyWeightData([]);
    }

    if (data && data?.uniformity) {
      setUniformityData(
        mapAndSortData(data?.uniformity, ['uniformity_standart', 'uniformity_actual'])
      );
    } else {
      setUniformityData([]);
    }

    if (data && data?.weekly_gain) {
      setWeeklyGainData(
        mapAndSortData(data?.weekly_gain, ['weekly_gain', 'standard_weekly_gain'])
      );
    } else {
      setWeeklyGainData([]);
    }
  }

  return (
    <Container>
      <Row className="px-5 py-4">
        <Row className="justify-content-md-center">
          <Col>
            <div className="tx-bold mt-2" style={{ fontSize: "27px" }}>
              Dashboard
              {" "}{moment(filters?.start_date, "DD-MM-YYYY").format("DD MMM YYYY")}
              {" "}-
              {" "}{moment(filters?.end_date, "DD-MM-YYYY").format("DD MMM YYYY")}
            </div>
          </Col>
          <Col>
            <Row>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  color="secondary"
                  variant="outlined"
                  disableElevation
                  sx={{
                    borderRadius: 28,
                    paddingY: "10px",
                    textTransform: "capitalize",
                    color: "black",
                    fontWeight: "800",
                  }}
                  size="large"
                  className={`filters`}
                  onClick={(e) => setShowModalFilter(!showModalFilter)}
                >
                  {filters?.start_date || filters?.end_date || filters?.id_farm || filters?.id_cage ? (
                    <span className="dot"></span>
                  ) : null}
                  Filter
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <GrafikCard
              cardTitle="Cumulative Mortality"
              tooltipTitle={infoGraphDashboard.CumulativeMortality}
              backgroundHeader={colorGraphs.Mustard}
              content={
                <LineChart
                  labels={tkChartData?.map(item => item.label)}
                  datasets={[
                    {
                      label: "Mort. Cum. Actual",
                      labelPrepend: "%",
                      data: tkChartData?.map(item => item.mortality),
                      fill: false,
                      borderColor: colorGraphs.OldBlue,
                      pointBackgroundColor: colorGraphs.OldBlue,
                    },
                    {
                      label: "Mort. Cum. Std",
                      labelPrepend: "%",
                      data: tkChartData?.map(item => item.standart),
                      fill: false,
                      borderColor: colorGraphs.Red,
                      pointBackgroundColor: colorGraphs.Red,
                      borderDash: [5, 5],
                    },
                  ]}
                  height={400}
                  stepSize={1}
                  scaleTitleX="Age (Weeks)"
                  scaleTitleY="%"
                  yTickDisplay
                  id="canvas-1"
                />
              }
              indikators={indikatorGrafikCumMortality}
            />
            <GrafikCard
              cardTitle="Feed Intake"
              tooltipTitle={infoGraphDashboard.FeedConsumption}
              backgroundHeader={colorGraphs.Purple}
              content={
                <LineChart
                  labels={feedConsumptionData?.map(item => item.label)}
                  datasets={[
                    {
                      label: "Feed Intake",
                      labelPrepend: " kg",
                      data: feedConsumptionData?.map(item => item.feed_intake),
                      fill: false,
                      borderColor: colorGraphs.Red,
                      pointBackgroundColor: colorGraphs.Red,
                      yAxisID: 'y',
                      order: 0
                    },
                    {
                      label: "Feed Intake Std",
                      labelPrepend: " kg",
                      data: feedConsumptionData?.map(item => item.feed_intake_standart),
                      fill: false,
                      borderColor: colorGraphs.Green,
                      pointBackgroundColor: colorGraphs.Green,
                      yAxisID: 'y',
                      order: 1
                    },
                    {
                      label: "Water Intake Std",
                      labelPrepend: "l",
                      data: waterConsumptionData?.map(item => item.water_intake_standart),
                      fill: false,
                      borderColor: colorGraphs.OldBlue,
                      pointBackgroundColor: colorGraphs.OldBlue,
                      borderDash: [5, 5],
                      yAxisID: 'y1',
                      order: 2
                    },
                  ]}
                  height={400}
                  stepSize={1}
                  scaleTitleX="Age (Weeks)"
                  scaleTitleY="Feed (Kg)"
                  scaleTitleY1="Water (L)"
                  yTickDisplay
                  id="canvas-2"
                />
              }
              indikators={indikatorGrafikFeedConsumption}
            />
            <GrafikCard
              cardTitle="Weekly Mortality"
              tooltipTitle={infoGraphDashboard.BodyWeightMortality}
              backgroundHeader={colorGraphs.Green}
              content={
                <LineChart
                  labels={weeklyMortalityData?.map(item => item.label)}
                  datasets={[
                    {
                      label: "Weekly Mortality",
                      labelPrepend: "%",
                      data: weeklyMortalityData?.map(item => item.weekly_mortality),
                      fill: false,
                      borderColor: colorGraphs.OldBlue,
                      pointBackgroundColor: colorGraphs.OldBlue,
                      yAxisID: 'y1',
                      order: 0
                    },
                    {
                      label: "Body Weight Actual",
                      labelPrepend: "g",
                      data: bodyWeightData?.map(item => item.body_weight_actual),
                      type: "bar",
                      borderColor: colorGraphs.Green,
                      backgroundColor: colorGraphs.Green,
                      borderWidth: 0,
                      minBarLength: 1,
                      fill: true,
                      yAxisID: 'y',
                      order: 2
                    },
                    {
                      label: "Standart Mortality (%)",
                      labelPrepend: "%",
                      data: tkChartData?.map(item => item.standart),
                      borderColor: colorGraphs.Red,
                      pointBackgroundColor: colorGraphs.Red,
                      borderDash: [5, 5],
                      fill: false,
                      yAxisID: 'y1',
                      order: 1
                    },
                  ]}
                  height={400}
                  stepSize={1}
                  scaleTitleX="Age (Weeks)"
                  scaleTitleY="Body Weight (g)"
                  scaleTitleY1="Mortality (%)"
                  yTickDisplay
                  id="canvas-4"
                />
              }
              indikators={indikatorGrafikWeeklyMortality}
            />
            <GrafikCard
              cardTitle="Body Weight Uniformity"
              tooltipTitle={infoGraphDashboard.BodyWeightUniformity}
              backgroundHeader={colorGraphs.Orange}
              content={
                <LineChart
                  labels={bodyWeightData?.map(item => item.label)}
                  datasets={[
                    {
                      label: "Body Weight Actual",
                      labelPrepend: "g",
                      data: bodyWeightData?.map(item => item.body_weight_actual),
                      fill: false,
                      borderColor: colorGraphs.Red,
                      pointBackgroundColor: colorGraphs.Red,
                      yAxisID: 'y',
                      order: 1
                    },
                    {
                      label: "Body Weight Std",
                      labelPrepend: "g",
                      data: bodyWeightData?.map(item => item.body_weight_standart),
                      fill: false,
                      borderColor: colorGraphs.Green,
                      pointBackgroundColor: colorGraphs.Green,
                      yAxisID: 'y',
                      order: 1
                    },
                    {
                      label: "Uniformity Std Min",
                      labelPrepend: "%",
                      data: uniformityData?.map(item => item.uniformity_standart[0]?.toString()),
                      fill: false,
                      borderWidth: 0,
                      backgroundColor: colorGraphs.LightBlue,
                      yAxisID: 'y1',
                      order: 2
                    },
                    {
                      label: "Uniformity Std Max",
                      labelPrepend: "%",
                      data: uniformityData?.map(item => item.uniformity_standart[1]?.toString()),
                      fill: "-1",
                      borderWidth: 0,
                      backgroundColor: colorGraphs.LightBlue,
                      yAxisID: 'y1',
                      order: 2
                    },
                    {
                      label: "Uniformity Actual",
                      labelPrepend: "%",
                      data: uniformityData?.map(item => item.uniformity_actual),
                      fill: false,
                      borderColor: colorGraphs.Black,
                      pointBackgroundColor: colorGraphs.Black,
                      yAxisID: 'y1',
                      order: 0
                    },
                  ]}
                  height={400}
                  stepSize={1}
                  scaleTitleX="Age (Weeks)"
                  scaleTitleY="Body Weight (g)"
                  scaleTitleY1="Acuan Uniformity (%)"
                  yTickDisplay
                  id="canvas-6"
                />
              }
              indikators={indikatorGrafikUniformity}
            />
            <GrafikCard
              cardTitle="Weekly Body Weight Gain"
              tooltipTitle={infoGraphDashboard.WeeklyGain}
              backgroundHeader="#FBBC04"
              content={
                <LineChart
                  labels={weeklyGainData?.map(item => item.label)}
                  datasets={[
                    {
                      label: "Weekly Body Weight Std",
                      labelPrepend: " g",
                      minBarLength: 1,
                      data: bodyWeightData?.map(item => item.body_weight_standart),
                      type: "bar",
                      borderColor: colorGraphs.Red,
                      backgroundColor: colorGraphs.Red,
                      borderWidth: 0,
                      minBarLength: 1,
                      fill: true,
                      yAxisID: 'y',
                      order: 1
                    },
                    {
                      label: "Weekly Body Weight Actual",
                      labelPrepend: " g",
                      minBarLength: 1,
                      data: bodyWeightData?.map(item => item.body_weight_actual),
                      type: "bar",
                      borderColor: colorGraphs.Grey,
                      backgroundColor: colorGraphs.Grey,
                      borderWidth: 0,
                      minBarLength: 1,
                      fill: true,
                      yAxisID: 'y',
                      order: 1
                    },
                    {
                      label: "Weekly Gain Actual",
                      labelPrepend: " g",
                      data: weeklyGainData?.map(item => item.weekly_gain),
                      fill: false,
                      borderColor: colorGraphs.Yellow,
                      pointBackgroundColor: colorGraphs.Yellow,
                      yAxisID: 'y1',
                      order: 0
                    },
                    {
                      label: "Weekly Gain Std",
                      labelPrepend: "%",
                      data: weeklyGainData?.map(item => item.standard_weekly_gain),
                      fill: false,
                      borderColor: colorGraphs.BlueGreen,
                      pointBackgroundColor: colorGraphs.BlueGreen,
                      borderDash: [5, 5],
                      yAxisID: 'y1',
                      order: 0
                    },
                  ]}
                  height={400}
                  stepSize={1}
                  scaleTitleX="Age (Weeks)"
                  scaleTitleY="Acuan Weight (g)"
                  scaleTitleY1="Acuan Gain Std (g)"
                  yTickDisplay
                  id="canvas-7"
                />
              }
              indikators={indikatorGrafikWeightGain}
            />
          </Col>
        </Row>
      </Row>
      <ModalFilterGrafik
        show={showModalFilter}
        setShow={setShowModalFilter}
      />
    </Container>
  );
};

const mapStateToProps = ({ listGraph }) => {
  return {
    filters: getFilterGraph(listGraph),
  };
};

export default connect(mapStateToProps, {
  listGraph,
  filterGraph
})(Index);

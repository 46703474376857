import React from "react";
import Card from "react-bootstrap/Card";
import { Col, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

const GrafikCard = ({
  cardTitle,
  content,
  indikators,
  styles,
  backgroundHeader,
  isCardVertikal,
  tooltipTitle
}) => {
  return (
    <>
      <Card
        style={{
          marginTop: "4rem",
          borderRadius: "1rem",
        }}>
        <Card.Header
          as="h6"
          style={{
            ...styles,
            backgroundColor: backgroundHeader ?? "inherit",
            fontSize: "16px",
            borderStartStartRadius: "1rem",
            borderStartEndRadius: "1rem",
            color: "white",
            padding: "0.8rem",
          }}
        >
          <div className="d-flex align-items-center">
            {cardTitle}
            {tooltipTitle && (
              <>
                <span
                  className="material-icons"
                  style={{ fontSize: 18, marginLeft: "5px" }}
                  data-tip
                  data-for={cardTitle}
                >
                  info_outlined
                </span>
                <ReactTooltip id={cardTitle} place="top" effect="solid">
                  <p style={{ whiteSpace: "pre-line", maxWidth: "15vw" }}>
                    {tooltipTitle}
                  </p>
                </ReactTooltip>
              </>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {content}
          <div className="d-flex justify-content-center align-items-center chart-stat-wrapper">
            {isCardVertikal ? (
              indikators.map((indikator, index) => {
                return (
                  <Col>
                    <Row key={index}>
                      <div>
                        <img src={indikator.imageUrl} width="20%" />
                      </div>
                      <div style={{ fontSize: "0.75rem" }}>
                        {indikator.titleIndikator}
                      </div>
                    </Row>
                  </Col>
                );
              })
            ) : (
              <Row>
                {indikators.map((indikator, key) => {
                  return (
                    <Col key={key} sm="auto">
                      <div width="100%">
                        <img src={indikator.imageUrl} width="auto" />
                      </div>
                      <div style={{ fontSize: "0.75rem" }}>
                        {indikator.titleIndikator}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default GrafikCard;
